@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap');

/** slider dots **/
.macau_testimonila_bg_img{
  background-image: url('../img/testimonial_bg.jpg');
  background-size: cover;
  width: 100%;
height: 500px;
  background-repeat: no-repeat;
  	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}
.mc_shop_by_shap_section {
  background-color: #c3e75d;
  padding: 60px 0!important;
}
.mc_category_section {
	padding: 25px 0 15px;
}

body .slick-dots li button::before{
  content: "";
  border: 2px solid #000;
  background: #fff;

}
.macau._banner_slider_section img {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
}
body .slick-dots {
  bottom: 0px;
  position: static !important;
}
body .slick-dots li button::before {
  content: "";
  border: 4px solid #0a3c39 !important;
  background: #fff;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  opacity: 1;
}
body .slick-dots li button{
  padding: 0px !important;
}
body .slick-dots li{
  height: 12px;
  width: 12px;
}
/** slider dots **/
.containerV1 {
  max-width: 1670px !important;
}
.fontMessiri{
  font-family: 'El Messiri', sans-serif !important;
}
 .picture:hover img {
    transform: scale(1.1);
  }
  .transition{
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
  }
  .picture + .info {
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    bottom: 0;
    left: 0;
    padding: 10px 25% 14px 16px;
    background-color: #fff;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -moz-transition: -moz-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    text-align: left;
  }
  .box_img:hover .info {
    -webkit-transform: unset;
    -moz-transform: unset;
    transform: unset;
  }
  .box_img .title {
    font-size: 25px;
    color: #0a3c39;
  }
  .buttonLink {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    color: #0a3c39;
    transition: .5s;
    overflow: hidden;
    font-family: 'El Messiri', sans-serif;
    border-bottom: 2px solid #0a3c39;
  }

 .twoBanner .banner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 2px solid #fff;
	padding: 30px;
	box-shadow: 0 15px 25px rgba(0, 0, 0, .08);
	width: max-content;
	height: max-content;
}
  .twoBanner .bg-banner {
    background-color: #fff;
    padding: 50px;
  }
  .title_heading {
    font-size: 33px;
    font-weight: 500;
    line-height: 1.2;
    color: #0a3c39;
    text-transform: none;
    border: none;
      border-top-color: currentcolor;
      border-right-color: currentcolor;
      border-bottom-color: currentcolor;
      border-left-color: currentcolor;
    border-color: #0a3c39;
    padding-bottom: 15px;
    display: inline-block;
  }
  .twoBanner p {
    color: #0a3c39;
    letter-spacing: 20px;
  }
  .blogCarousel .date {
    background-color: #fff;
    border-radius: 0 20px;
    box-shadow: 0 3px 10px rgba(0,0,0,.08);
    color: #0a3c39;
    font-weight: 600;
    height: 68px;
    left: 10px;
    padding: 10px 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 10px;
    width: 68px;
    display: none;
}
  /* .blogCarousel .date::after {
    content: '';
    background-color: #000;
    width: 27px;
    height: 1px;
    display: block;
    margin: 0 auto;
      margin-top: 0px;
    margin-top: 4px;
  } */
  .colorGrey{
    color: #0a3c39;
  }
  .title-blog a::after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 50px;
    background: #6b8181;
  }
  .blogCarousel{
    margin-bottom: 30px;
  }
  .bgGrey {
    background: #c3e75d;
  }
  .fs15{
    font-size: 15px !important;
  }
  .maqauInput {
    border: 1px solid #0a3c39 !important;
  }
  .imgWrapper,.imgWrapper1{
    width: 70px;
margin: auto;
height: 70px;
  }

.imgWrapper img {
  opacity: 0.50;
    width: 100%;
    height: 100%;

}
.line_clamp_testimonila {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 4;
  color: #0a3c39;
}
.macau_testimonila_bg_img a{
  color: #0a3c39;
}
.shapeBySl h3.RingSettingTitleSPL{
font-size: 15px;
text-transform: uppercase;
position: relative;
padding-top: 10px;
text-align: center;
color: #0a3c39;
}
.buttonLink a{
  color: #0a3c39 !important;
}
.info_blog h4 a{
  color: #0a3c39 !important;
}
.macau_testimonila_bg_img p, .macau_testimonila_bg_img a{
  color: #0a3c39 !important;
}
.noBtn{
  background: transparent !important;
  outline: none;
  border: none;
}
.socialIcons a svg {
	color: #FFF;
	/* width: 22px; */
	/* height: 22px; */
	font-size: 18px;
	margin: 0px 15px 0 0;
}
.socialIcons a svg:hover{
  color:#FFF ;
}
.paymentlogos ul li {
  display: inline-block;
  filter: grayscale(1);
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
  margin: 0 8px;
  opacity: 40%;
  transition: all 0.5s;
}
.paymentlogos img {
  width: 100%;
  height: 18px;
}
.home .machauHeader {
  position: absolute;
  width: 100%;
  z-index: 999;
}
.home .rcs_header {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.home #rcs_header_seaction{
  border: none
}
@media only screen and (min-width:768px) {
  .slider_for_mobile{
    display: none;
  }
}
@media only screen and (max-width:767px) {
  .macau_testimonila_bg_img {
	height: 500px;
}
 .slider_for_desktop{
    display: none !important;
  }
  body .ptMob30{
    padding-top: 30px !important;
  }
  body .pbMob30{
    padding-bottom: 30px !important;
  }
  body.home .machauHeader{
    position: static;
  }
  body .twoBanner .banner {
    position: static;
    width: 100%;
    transform: translate(0px,0px);
  }
}

.bannerSliderHome .slick-dots {
  position: absolute !important;
  bottom: 30px;
}
.pt80 {
	padding-top: 25px;
}
.pb80{
  padding-bottom: 80px;
}
@media only screen and (max-width: 1400px) and (min-width: 1200px) {

  body .containerV1 {
    max-width: 1140px !important;
  }
.title_heading {
	font-size: 23px;
}
.macau_testimonila_bg_img {
	height: 400px;
}
.twoBanner p {
	letter-spacing: 15px;
}
.twoBanner .bg-banner {
	padding: 40px;
}
.shapeBySl h3.RingSettingTitleSPL {
	font-size: 14px;
}
.line_clamp_testimonila {
	font-size: 14px;
}
.info_blog p {
	font-size: 14px;
	line-height: 20px;
}
.buttonLink {
	font-size: 14px;
}
.blogCarousel .date {
	width: 58px;
	height: 58px;
	font-size: 13px;
}
.blog_cate {
	font-size: 14px;
}
}
body .title-blog {
  font-size: 22px;
}
.shapeBySl .slick-track {
  text-align: center;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  body .title_heading {
    font-size: 30px;
  }
}
@media(min-width:768px)  and (max-width:1024px){
  .macau_testimonila_bg_img {
	height: 500px;
}
}

@media(min-width:1200px) and (max-width:1440px){
  .mc_shop_by_shap_section {
    padding: 50px 0;
  }
}